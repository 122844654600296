import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UserPersonaInfo } from '../utils/types/user-persona-info.type';
import { DataSet } from '../../../shared/utils/types/data-set.type';
import { Match } from '../../user-match/utils/types/match.type';
import { HttpModel } from '../../../shared/models/http.model';
import { MatchStatus } from '../../user-match/utils/types/match-status.type';
import { PersonaStatus } from '../utils/types/persona-status.type';
import { BulkChange } from '../../../../../projects/admin/src/app/user-management/utils/types/bulk-change.type';
import { Persona } from '@imentor-user/user-persona/utils/types/persona.type';
import { WindowService } from '../../../core/services/window.service';
import { Credentials } from '../../../../../projects/auth/src/app/utils/types/credentials.type';

/**
 * User persona service
 */
@Injectable()
export class UserPersonaService {

  /**
   * Base URL for personas blueprint
   * @type { string }
   */
  private readonly BASE_URL = '/personas';

  /**
   * Dependency injection of HttpClient class and window service
   *
   * `HttpClient` is available as an injectable class, with methods to perform HTTP requests.
   * Each request method has multiple signatures, and the return type varies according to which
   * signature is called (mainly the values of `observe` and `responseType`).
   * @param { HttpClient } http
   * @param { WindowService } windowService
   */
  constructor(private http: HttpClient, private windowService: WindowService) {}

  /**
   * Edit partner site
   * @param { any } partner_site
   * @param { number } persona_id
   * @returns { Observable<any> }
   */
  public editPartnerSites(partner_site: any, persona_id: number): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/${persona_id}/partner-site`, {
      id: partner_site.id,
      name: partner_site.name
    }).pipe(map((response: any) => response));
  }

  /**
   * Add partner site
   * @param { any } partner_site
   * @param { number } persona_id
   * @returns { Observable<any> }
   */
  public addPartnerSites(partner_site: any, persona_id: number): Observable<any> {
    return this.http.post(`${this.BASE_URL}/${persona_id}/partner-site`, {
      id: partner_site.id,
      name: partner_site.name
    }).pipe(map((response: any) => response));
  }

  /**
   * Re-open match
   * @param { number } match_history_id
   * @returns { Observable<string> }
   */
  public reOpenMatch(match_history_id: number): Observable<string> {
    return this.http.post(`${this.BASE_URL}/reopen-match`, { match_history_id }).pipe(
      map((response: { filename: string }) => response.filename)
    );
  }

  /**
   * Add User Persona
   * @param { number } user_id
   * @returns { Observable<string> }
   */
  public addUserPersona(user_id: number): Observable<string> {
    return this.http.post(`${this.BASE_URL}/new-rs-persona`, {
      user_id
    }).pipe(map((response: any) => response.filename));
  }

  /**
   * Load Match Statuses
   * @returns { Observable<Array<MatchStatus>> }
   */
  public loadMatchStatuses(): Observable<Array<MatchStatus>> {
    return this.http.get(`${this.BASE_URL}/match-statuses`)
      .pipe(map((response: Array<MatchStatus>) => response));
  }

  /**
   * Load persona statuses
   * @returns { Observable<Array<PersonaStatus>> }
   */
  public personaStatuses$: Observable<Array<PersonaStatus>> = this.http.get(`${this.BASE_URL}/statuses`)
      .pipe(map((response: Array<PersonaStatus>) => response));

  /**
   * Update user's persona
   * @param { Partial<UserPersonaInfo> } persona
   * @returns { Observable<UserPersonaInfo> }
   */
  public updatePersona(persona: Partial<UserPersonaInfo>): Observable<UserPersonaInfo> {
    const { id, ...personaWithoutId } = persona;
    return this.http.patch(`${this.BASE_URL}/${persona.id}`, personaWithoutId)
      .pipe(map((response: UserPersonaInfo) => response));
  }

  /**
   * Search Personas
   * @param { number } user_id
   * @returns { Observable<DataSet<Match>> }
   */
  public searchPersonas(user_id?: number): Observable<DataSet<Match>> {
    if (user_id) {
      return this.http.get(`${this.BASE_URL}/search`, {
        params: new HttpModel().setParams({ user_id })
      }).pipe(map((response: DataSet<Match>) => response));
    } else {
      return of(null);
    }
  }

  /**
   * Change persona match status
   * TODO: Check this method whether needs to return response or reload the page
   * @param { MatchStatus } match_status
   * @param { number } persona_id
   * @returns { Observable<any> }
   */
  public changeMatchStatus( match_status: MatchStatus, persona_id: number ): Observable<any> {
    return this.http.patch(`${this.BASE_URL}/${persona_id}`, { match_status })
      .pipe(map((response: any) => {
        this.windowService.locationReload();
        return response;
      }));
  }

  /**
   * Update persona status for user with match status NM
   * @param { Partial<UserPersonaInfo> } persona
   * @returns { Observable<UserPersonaInfo> }
   */
  public updatePersonaStatus(persona: Partial<UserPersonaInfo>): Observable<UserPersonaInfo> {
    return this.http.patch(`${this.BASE_URL}/${persona.id}/update-status-for-nm`, {
      status: persona.status
    }).pipe(map((response: UserPersonaInfo) => response));
  }

  /**
   * Method for bulk update Partner Sites
   * @param { BulkChange } bulkChange
   * @returns { Observable<Array<Persona>> }
   *
   */
  public bulkUpdatePartnerSites(bulkChange: BulkChange): Observable<Array<Persona>> {
    return this.http.post(`${this.BASE_URL}/partner-site/bulk`, {
      persona_ids: bulkChange.persona_ids,
      partner_site_id: bulkChange.partner_site_id
    }).pipe(map((response: Array<Persona>) => response));
  }

  /**
   * Create new mentor persona
   * @param { Credentials } credentials
   * @returns { Observable<string> }
   */
  public createNewMentorPersona(credentials: Credentials): Observable<string> {
    return this.http.post(`${this.BASE_URL}/new-mentor-persona`, {
      email: credentials.email,
      password: credentials.password
    }).pipe(map((response: any) => response.url));
  }
}
