import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '../../../src/environments/environment';
import { initializeSentry } from '@imentor-shell-app/shared/helpers/sentry.helper';

if (environment.production) {
  enableProdMode();
}

initializeSentry();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
