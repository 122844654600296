import {
  ActionReducerMap, MemoizedSelector,
  createSelector, createFeatureSelector,
} from '@ngrx/store';
import * as fromAuth from './reducers/auth.reducer';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface AuthenticationState {
  /**
   * Authentication state
   */
  authentication: fromAuth.State;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<AuthenticationState, any> = {
  authentication: fromAuth.reducer,
};

/**
 * Authentication Reducer state
 * @type {MemoizedSelector<object, State>}
 */
export const getAuthenticationState = createFeatureSelector<fromAuth.State>('authentication');

/**
 * Get error message state from authentication reducer
 * @type {MemoizedSelector<State, string>}
 */
export const getLoginErrorMessageState = createSelector(getAuthenticationState, fromAuth.getLoginErrorMessage);

/**
 * Get request reset password error message state from authentication reducer
 * @type {MemoizedSelector<State, string>}
 */
export const getRequestResetPasswordErrorMessage = createSelector(getAuthenticationState,
                                                                fromAuth.getRequestResetPasswordErrorMessage);

/**
 * Get sign up error message state from authentication reducer
 * @type {MemoizedSelector<State, string>}
 */
export const getSignUpErrorMessageState = createSelector(getAuthenticationState, fromAuth.getSignUpErrorMessage);

/**
 * Get reset password error message state from authentication reducer
 * @type {MemoizedSelector<State, string>}
 */
export const getResetPasswordErrorMessage = createSelector(getAuthenticationState, fromAuth.getResetPasswordErrorMessage);

/**
 * Get expired link reset password error message state from authentication reducer
 * @type {MemoizedSelector<State, string>}
 */
export const getExpiredLinkResetPasswordErrorMessage =
  createSelector(getAuthenticationState, fromAuth.getExpiredLinkResetPasswordErrorMessage);

/**
 * Get submit button state from authentication reducer
 * @type {MemoizedSelector<State, boolean>}
 */
export const getSubmitButtonState = createSelector(getAuthenticationState, fromAuth.getSubmitButtonState);

/**
 * Get password strength result state from authentication reducer
 * @type {MemoizedSelector<State, PasswordStrengthResult>}
 */
export const getPasswordStrengthResult = createSelector(getAuthenticationState, fromAuth.getPasswordStrengthResult);
