import { Item } from '../utils/types/item.type';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpParamEncoderHelper } from '../helpers/custom-http-param-encoder.helper';
import { HttpRequestOptions } from '../../core/utils/types/http-request-options.type';
import { FileManagerConfig } from '../../core/utils/types/file-manager-config.type';

/**
 * Http Helper class for getting file name from http response content disposition, setting params for http request,
 * setting params based on passed object
 */
export class HttpModel {

  /**
   * Parameters list of items
   * @type {Array<Item>}
   */
  private parameters: Array<Item> = [];

  /**
   * Derives file name from the http response
   * by looking inside content-disposition
   * @param {Response} response
   * @returns string
   */
  static getFileNameFromHttpResponseContentDisposition = (response: Response): string => {
    const contentDisposition = response.headers.get('content-disposition');
    const matches = /filename=([^;]+)/ig.exec(contentDisposition);
    return matches && matches.length > 1 ? matches[1] : null;
  }

  /**
   * Set params for http request
   * @param configParams
   * @returns HttpParams
   */
  static setParamsForHttpRequest(configParams: Array<Item>): HttpParams {
    let params: HttpParams = new HttpParams({ encoder: new CustomHttpParamEncoderHelper() });
    configParams.forEach((param: Item) => {
      params = params.append(param.key, param.value);
    });
    return params;
  }

  /**
   * Set HTTP request options
   * @param {FileManagerConfig} config
   * @return {HttpRequestOptions}
   */
  static setHttpRequestOptions(config: FileManagerConfig): HttpRequestOptions {
    let options: HttpRequestOptions = {
      headers: new HttpHeaders({ 'Content-Type': config.requestContentType }),
      responseType: config.responseType,
      observe: 'response',
    };

    if (config.params) {
      options = {
        ...options,
        params: config.params instanceof Array ? HttpModel.setParamsForHttpRequest(config.params) : config.params
      };
    }

    return options;
  }

  /**
   * Set key-value pairs for params and return set params for http request
   * @param objectWithParams
   * @returns HttpParams
   */
  public setParams = (objectWithParams: any): HttpParams  => {

    const setKeyValuePair = (key: string, value: any) => {
      if (value instanceof Array) {
        key += '[]';
        value.forEach(val => {
          setKeyValuePair(key, val);
        });
      } else {
        if (typeof value === 'string') {
          this.parameters.push({ 'key': key, 'value': value });
        } else if (typeof value === 'object') {
          this.setParams(value);
        } else {
          value = value.toString();
          setKeyValuePair(key, value);
        }
      }
    };

    Object.keys(objectWithParams).forEach(key => {
      const value = objectWithParams[key];
      if (value != null && typeof value !== 'undefined' && value !== '') {
        setKeyValuePair(key, value);
      }
    });

    return HttpModel.setParamsForHttpRequest(this.parameters);
  }
}
