import { createAction, props, union } from '@ngrx/store';
import { Credentials } from '../../utils/types/credentials.type';

/***
 * Create new mentor persona
 */
export const create = createAction(
  '[MentorPersona] create new mentor persona',
  props<{ credentials: Credentials }>()
);

/***
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
const all = union({
  create,
});

/***
 * Mentor Persona action type
 */
export type MentorPersonaActionsUnion = typeof all;
