import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import { RouterInterceptor } from '@imentor-shell-app/shared/interceptors/router.interceptor';
import { MemberTypes } from "@imentor-member-site/utils/enums/member-types.enum";
import { MemberSiteInfo } from '@imentor-member-site/utils/models/member-site-info.model';
import { MemberSiteService } from '@imentor-member-site/services/member-site.service';
import { MemberNames } from '@imentor-member-site/utils/enums/member-names.enum';

import * as fromAuthRoot from './store';

/**
 * iMentor Authentication root component
 */
@Component({
  selector: 'imentor-auth-root',
  templateUrl: './app.component.html'
})
export class AppAuthComponent implements OnInit {

  /**
   * Member site info observable
   * @type {Observable<MemberSiteInfo>}
   */
  public memberSiteInfo$: Observable<MemberSiteInfo>;

  /**
   * Background images url
   * @type {string}
   */
  private readonly BACKGROUND_IMAGES: string = '/static-fe/auth/assets/img/';

  /**
   * Dependency injection of router, title service, background image service, and activated route.
   *
   * Call method for setting background image
   * @param { Router } router
   * @param { Title } titleService
   * @param { ActivatedRoute } activatedRoute
   * @param { Renderer2 } renderer
   * @param {MemberSiteService} memberSiteService
   * @param { Store<fromAuthRoot.AuthenticationState> } store
   */
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private renderer: Renderer2,
    private memberSiteService: MemberSiteService,
    private store: Store<fromAuthRoot.AuthenticationState>
  ) {

    RouterInterceptor.intercept(router, store);

    this.memberSiteInfo$ = memberSiteService.memberSiteInfo$;

    this.memberSiteInfo$.pipe(take(1)).subscribe((memberSiteInfo: MemberSiteInfo) => {
      if (memberSiteInfo) {
        this.setBackgroundImage(memberSiteInfo);
      }
    });
  }

  /**
   * On application init set page title and when user navigate through application change page title,
   * Listen to router events and on every change get data[title] passed from router and apply it.
   */
  ngOnInit() {
    this.router.events
      .pipe(
        map((event: RouterEvent) => event),
        filter((event: RouterEvent) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        map((route) => route),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((routeData) => {
        const pageTitle: string = routeData['pageTitle'] + ' | iMentor';

        this.setTitle(pageTitle);
      });
  }

  /**
   * Set page title
   * @param {string} newTitle
   */
  private setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  /**
   * Method for setting background image for particular member site
   * @param { MemberSiteInfo } memberSiteInfo
   */
  private setBackgroundImage(memberSiteInfo: MemberSiteInfo) {
    if (memberSiteInfo.type_template.name === MemberTypes.PARTNER) {
      let images: Array<string> = ['bg2.jpg', 'bg3.jpg', 'bg4.jpg', 'bg5.jpg', 'bg6.jpg'];
      if (memberSiteInfo.name === MemberNames.STUDENT_SPONSOR_PARTNERS) {
        images = ['bg7.jpg', 'bg8.jpg'];
      }
      const imageRoot: string = `url(${this.BACKGROUND_IMAGES + images[Math.floor(Math.random() * images.length)]})`;
      const backgroundStyle: string = `transparent ${imageRoot} center center fixed no-repeat`;
      this.renderer.setStyle(document.body, 'background', backgroundStyle);
      this.renderer.setStyle(document.body, 'background-size', 'cover');
      this.renderer.setAttribute(document.body, 'class', 'is-mentor-2');
    } else {
      this.renderer.setAttribute(document.body, 'class', 'is-not-mentor-2');
    }
  }
}
