import { Environments } from '../app/core/utils/enums/environments.enum';

/**
 * Test/Dev environment
 */
export const environment = {
  production: true,
  name: Environments.TEST,
  socket: {
    url: '/chat',
    config: {}
  },
  /**
   * Sentry DSN configuration for environment
   */
  sentry: {
    dsn: 'https://3339e86d10d54710aa9a6929e91965dd@sentry.io/146850',
    sampleRate: 1.0,
  },
  google: {
    gtag_id: null,
    gtm_id: null
  },
  userWay: {
    accountId: null
  }
};
