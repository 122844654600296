import { createAction, props, union } from '@ngrx/store';

/**
 * Action for authentication success
 */
export const authSuccess = createAction(
  '[Authentication/API] Authentication Success',
);

/**
 * Action for login failure
 */
export const loginFailure = createAction(
  '[Authentication/API] Login Failure',
  props<{ message: string }>()
);

/**
 * Sign up failure action
 */
export const signUpFailure = createAction(
  '[Authentication/API] Sign up Failure',
  props<{ message: string }>()
);

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
const all = union({
  authSuccess,
  loginFailure,
  signUpFailure,
});

/**
 * Authentication Api Actions Types
 */
export type AuthenticationApiActionsUnion = typeof all;
