import { createAction, union } from '@ngrx/store';

/**
 * Load application info list action
 */
export const start = createAction(
  '[Application] start intake application for current user',
);

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
const all = union({
  start,
});

/**
 * Application info action type
 */
export type ApplicationActionsUnion = typeof all;
