import { HttpParameterCodec } from '@angular/common/http';

/**
 * Custom Http param encoder for special characters in appended HttpParams
 */
export class CustomHttpParamEncoderHelper implements HttpParameterCodec {

  /**
   * Method for Key encoder
   * @param {string} key
   * @returns {string}
   */
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  /**
   * Method for Value encoder
   * @param {string} value
   * @returns {string}
   */
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  /**
   * Method for Key decoder
   * @param {string} key
   * @returns {string}
   */
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  /**
   * Method for Value decoder
   * @param {string} value
   * @returns {string}
   */
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
