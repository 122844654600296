import { ViewContainerRef } from '@angular/core';

import { createAction, props, union } from '@ngrx/store';

import { PopUpSettings } from '@imentor-shared/modules/pop-up/utils/types/pop-up-settings.type';

import { Credentials } from '../../utils/types/credentials.type';
import { User } from '../../utils/types/user.type';

/**
 * Reset login error message action
 */
export const reset = createAction(
  '[Authentication] reset login error message'
);


/**
 * Login user action
 */
export const login = createAction(
  '[Authentication] login user',
  props<{ credentials: Credentials, popUpContainer: ViewContainerRef,
    passwordExpiredPopUpSettings: PopUpSettings, mentorClosedPopUpSettings: PopUpSettings }>()
);

/**
 * Action for signing up user
 */
export const signUp = createAction(
  '[Authentication] sign up user',
  props<{ user: User }>()
);

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
const all = union({
  reset,
  login,
  signUp,
});

/**
 * Authentication Actions Types
 */
export type AuthenticationActionsUnion = typeof all;
