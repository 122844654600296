import { ComponentRef, EmbeddedViewRef, Injectable, NgModuleRef, TemplateRef, ViewContainerRef } from '@angular/core';

import { LazyModuleLoaderService } from '../../../../core/services/lazy-module-loader.service';
import { PopUpModule } from '../pop-up.module';
import { PopUpComponent } from '../pop-up.component';

/**
 * Pop Up service
 */
@Injectable({ providedIn: 'root' })
export class PopUpService {
  /**
   * Pop Up module reference
   * @type { NgModuleRef<PopUpModule> }
   * @private
   */
  private popupModuleReference: NgModuleRef<PopUpModule>;

  /**
   * Pop Up component reference
   * @type { ComponentRef<PopUpComponent> }
   * @private
   */
  private popUpComponentRef: ComponentRef<PopUpComponent>;

  /**
   * Pop Up Body content reference
   * @type { EmbeddedViewRef<null> }
   * @private
   */
  private popUpBodyContentRef: EmbeddedViewRef<null>;

  /**
   * Pop Up service constructor
   * @param { LazyModuleLoaderService } lazyModuleLoaderService
   */
  constructor(private lazyModuleLoaderService: LazyModuleLoaderService) {}

  /**
   * Open Pop Up
   * Lazy load module if it is not already loaded
   * Close Pop Up if it already exists
   * Create a host view for a view container based on the component factory from the module
   * and node for embedded view created based on pop up body template reference if it exists
   * Store the reference in a component reference property
   * Return component instance in form of a promise
   * @param { ViewContainerRef } containerElementViewReference
   * @param { TemplateRef<null> } popUpBody
   * @return { Promise<PopUpComponent> }
   */
  public async openPopUp(containerElementViewReference: ViewContainerRef, popUpBody?: TemplateRef<null>): Promise<PopUpComponent> {
    if (!this.popupModuleReference) {
      this.popupModuleReference = await this.lazyModuleLoaderService.loadLazyModule(
        import('../pop-up.module').then(m => m.PopUpModule)
      );
    }

    if (this.isPopUpOpened()) {
      this.closePopUp();
    }

    if (popUpBody) {
      this.popUpBodyContentRef = containerElementViewReference.createEmbeddedView(popUpBody);
      this.popUpComponentRef = containerElementViewReference.createComponent(
        this.popupModuleReference.componentFactoryResolver.resolveComponentFactory(PopUpComponent),
        0,
        undefined,
        [this.popUpBodyContentRef.rootNodes]
      );
    } else {
      this.popUpComponentRef = containerElementViewReference.createComponent(
        this.popupModuleReference.componentFactoryResolver.resolveComponentFactory(PopUpComponent)
      );
    }

    return this.popUpComponentRef.instance;
  }

  /**
   * Close Pop Up
   * Destroy the component and body content instances and all the data structures associated with them
   * Assign null to a component reference and body content reference properties so that garbage collector can clear the memory
   */
  public closePopUp() {
    if (this.popUpBodyContentRef) {
      this.popUpBodyContentRef.destroy();
      this.popUpBodyContentRef = null;
    }
    if (this.isPopUpOpened()) {
      this.popUpComponentRef.destroy();
      this.popUpComponentRef = null;
    }
  }

  /**
   * Check if Pop Up is opened
   * @return boolean
   */
  public isPopUpOpened(): boolean {
    return !!this.popUpComponentRef;
  }
}
