import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import * as Sentry from '@sentry/angular';

import { ResponseInterceptor } from '@imentor-shell-app/core/errors/interceptor/response.interceptor';
import { ErrorsHandler } from '@imentor-shell-app/core/errors/handler/errors-handler';
import { MemberSiteService } from '@imentor-member-site/services/member-site.service';
import { UserPersonaService } from '@imentor-user/user-persona/services/user-persona.service';
import { environment } from '../../../../src/environments/environment';

import { AuthRoutingModule } from './app-routing.module';
import { AppAuthComponent } from './app.component';
import * as fromAuth from './store';
import { AuthEffects } from './store/effects/auth.effects';
import { AuthenticationService } from './services/authentication.service';
import { UserPasswordService } from './services/user-password.service';
import { ApplicationsService } from './services/applications.service';

/**
 * Auth root module
 */
@NgModule({
  declarations: [
    AppAuthComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(fromAuth.reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([AuthEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
  providers: [
    AuthenticationService,
    UserPasswordService,
    { provide: APP_INITIALIZER, useFactory: clearSessionCookiesFactory, deps: [ AuthenticationService ], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorsHandler },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    ApplicationsService,
    MemberSiteService,
    UserPersonaService
  ],
  bootstrap: [AppAuthComponent]
})
export class AppModule { }

/**
 * Create auth shared module for importing auth AppModule in root project AppModule
 */
@NgModule()
export class AuthSharedModule {
  static forRoot(): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
      providers: []
    };
  }
}

/**
 * Clear session cookies factory function
 * @param {AuthenticationService} provider
 */
export function clearSessionCookiesFactory(provider: AuthenticationService) {
  return () => provider.clearSessionCookies();
}
