import { environment } from '../../../environments/environment';

/**
 * User Way manager helper for handling accessibility on the platform
 */
export class UserWayManagerHelper {

  /**
   * State whether User Way manager is created
   * @type boolean
   */
  static isUserWayManagerCreated: boolean;

  /**
   * Initialization of User Way manager
   */
  static init() {
    if (environment.userWay.accountId && !UserWayManagerHelper.isUserWayManagerCreated) {
      UserWayManagerHelper.isUserWayManagerCreated = true;
      UserWayManagerHelper.createScript('https://cdn.userway.org/widget.js');
    }
  }

  /**
   * Method for creating User Way manager script
   * @param { string } source
   */
  static createScript(source: string) {
    const script = document.createElement('script');
    script.async = true;
    script.setAttribute('data-account', environment.userWay.accountId);
    script.setAttribute('src', source);
    document.body.appendChild(script);
  }
}
