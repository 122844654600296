import { createAction, props } from '@ngrx/store';

/**
 * Set header title action
 */
export const set = createAction(
  '[Layout] Set header title',
  props<{ headerTitle: string }>()
);

/**
 * Header title Action Type
 */
export type HeaderTitleActionsUnion = ReturnType<typeof set>;
