import { createAction, props } from '@ngrx/store';

/**
 * Set side nav state is opened or not action
 */
export const toggle = createAction(
  '[Layout] toggle side nav',
  props<{ isOpened: boolean }>()
);

/**
 * Side Nav Actions Type
 */
export type SideNavActionsUnion = ReturnType<typeof toggle>;
