import { createAction, props, union } from '@ngrx/store';
import { PasswordStrengthResult } from '@imentor-user/user-password/user-password-strength/utils/types/password-strength-result.type';

/**
 * Action for request reset password failure
 */
export const resetFailure = createAction(
  '[User Password/API] failed to reset password',
  props<{ message: string }>()
);

/**
 * Check Password Strength success
 */
export const checkSuccess = createAction(
  '[User Password/API] successfully checked password strength',
  props<{ passwordStrengthResult: PasswordStrengthResult }>()
);

/**
 * Action for reset password failure
 */
export const updateFailure = createAction(
  '[User Password/API] failed to update password',
  props<{ message: string, errorType: 'EXPIRED_LINK' | 'UPDATE_FAILURE' }>()
);

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
const all = union({
  resetFailure,
  checkSuccess,
  updateFailure,
});

/**
 * User password API Actions Types
 */
export type UserPasswordApiActionsUnion = typeof all;
