import { environment } from '../../../environments/environment';
import { NavigationEnd } from '@angular/router';

/**
 * @ignore
 */
declare var gtag;

/**
 * Google tag manager helper for handling all google analytics processes
 */
export class GoogleTagManagerHelper {

  /**
   * State whether google tag is created
   * @type {boolean}
   * @default {false}
   */
  static isGoogleTagCreated: boolean = false;

  /**
   * State whether google tag manager is created
   * @type {boolean}
   * @default {false}
   */
  static isGTMCreated: boolean = false;

  /**
   * Initialization of google tag manager with Google TAG
   * @param {NavigationEnd} event
   */
  static init(event: NavigationEnd) {
    if (environment.google.gtag_id && !GoogleTagManagerHelper.isGoogleTagCreated) {
      GoogleTagManagerHelper.isGoogleTagCreated = true;

      GoogleTagManagerHelper.createScript(`https://www.googletagmanager.com/gtag/js?id=${ environment.google.gtag_id }`);

      gtag('js', new Date());
      gtag('config', environment.google.gtag_id, { 'page_path': event.urlAfterRedirects });
    }
  }

  /**
   * Setting google tag manager with GTM ID
   * @param {NavigationEnd} event
   */
  static set(event: NavigationEnd) {
    if (environment.google.gtm_id && !GoogleTagManagerHelper.isGTMCreated) {
      GoogleTagManagerHelper.isGTMCreated = true;

      GoogleTagManagerHelper.createScript(`https://www.googletagmanager.com/gtm.js?id=${ environment.google.gtm_id }`);

      gtag('gtm.start', new Date().getTime());
      gtag('event', 'gtm.js');
      gtag({ 'pagePath': event.urlAfterRedirects });
    }
  }

  /**
   * Method for creating Google Tag manager script
   * @param {string} source
   */
  static createScript(source: string) {
    const script = document.createElement('script');
    script.async = true;
    script.src = source;
    document.head.appendChild(script);
  }
}
