import { Injectable } from '@angular/core';

/**
 * Window Service
 */
@Injectable({
  providedIn: 'root'
})
export class WindowService {

  /**
   * Method for reloading location
   */
  public locationReload() {
    window.location.reload();
  }

  /**
   * Method for replacing location
   * @type { string } url
   */
  public locationReplace(url: string) {
    window.location.assign(url)
  }

  /**
   * Method for setting location href
   * @type { string } href
   */
  public setLocationHref(href: string) {
    window.location.href = href;
  }

  /**
   * Method for replacing location href
   * @type { string } searchValue
   * @type { string } replaceValue
   * @return { string }
   */
  public replaceLocationHref(searchValue: string, replaceValue: string): string {
    return window.location.href.replace(searchValue, replaceValue);
  }

  /**
   * Method for opening window
   * @type { string } url
   * @type { string } target
   * @return { Window }
   */
  public open(url: string, target: string): Window {
    return window.open(url, target);
  }

  /**
   * Method for opening print preview
   */
  public print() {
    window.print();
  }

  /**
   * Method for scrolling
   * @type { number } x
   * @type { number } y
   */
  public scrollTo(x: number, y: number) {
    window.scrollTo(x, y);
  }

  /**
   * Method for checking if location hash includes given string
   * @type { string } url
   * @return { boolean }
   */
  public checkIfLocationHashIncludes(searchString: string): boolean {
    return window.location.hash.includes(searchString);
  }
}
