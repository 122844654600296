import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Application Service
 */
@Injectable()
export class ApplicationsService {

  /**
   * Base URL for applications
   * @type string
   * @private
   */
  private readonly BASE_URL: string = '/applications';

  /**
   * Dependency injection of HttpClient class
   *
   * `HttpClient` is available as an injectable class, with methods to perform HTTP requests.
   * Each request method has multiple signatures, and the return type varies according to which
   * signature is called (mainly the values of `observe` and `responseType`).
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient) {}

  /**
   * Method for applying on intake application with current user
   * @returns {Observable<string>}
   */
  public startApplication(): Observable<string> {
    return this.http.get(`${this.BASE_URL}/apply`).pipe(
      map((response: { url: string }) => response.url)
    );
  }
}
