import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';

import { Store } from '@ngrx/store';

import { GoogleTagManagerHelper } from '../helpers/google-tag-manager.helper';
import { UserWayManagerHelper } from '../helpers/user-way-manager.helper';
import { SideNavActions } from '../../core/layout/store/actions';
import { LoadingSpinnerActions } from '../../core/layout/store/actions';

/**
 * Router interceptor is used for activating loading spinner,
 * closing side nav, and initialization of Google Analytics tool
 */
export class RouterInterceptor {
  /**
   * Method for navigation loader interceptor.
   * Loading spinner is displayed when user navigate through app
   * @param {Router} router
   * @param {Store} store
   */
  static intercept(router: Router, store: Store<any>) {
    router.events.subscribe((e: RouterEvent) => {
      if (e instanceof NavigationStart) {
        store.dispatch(LoadingSpinnerActions.toggle({ isLoading: true }));
      } else if (e instanceof NavigationEnd) {
        GoogleTagManagerHelper.init(e);
        GoogleTagManagerHelper.set(e);

        UserWayManagerHelper.init();

        store.dispatch(LoadingSpinnerActions.toggle({ isLoading: false }));
        store.dispatch(SideNavActions.toggle({ isOpened: false }));
      } else if (e instanceof NavigationCancel || e instanceof NavigationError) {
        store.dispatch(LoadingSpinnerActions.toggle({ isLoading: false }));
      }
    });
  }
}
