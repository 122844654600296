import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { HttpModel } from '../../shared/models/http.model';
import { MemberSiteFilter } from '../utils/types/member-site-filter.type';
import { MemberSiteInfo } from '../utils/models/member-site-info.model';
import { AppHttpError } from '../../core/errors/common/app-http-error';

/**
 * Member site service
 */
@Injectable()
export class MemberSiteService {
  /**
   * Base url for member site
   * @type {string}
   */
  private readonly BASE_URL: string = '/member-sites';

  /**
   * Method for getting member site info from BE
   * @returns {Observable<void>}
   */
  public memberSiteInfo$: Observable<MemberSiteInfo> = this.getMemberSiteInfo();

  /**
   * Dependency injection of HttpClient class
   *
   * `HttpClient` is available as an injectable class, with methods to perform HTTP requests.
   * Each request method has multiple signatures, and the return type varies according to which
   * signature is called (mainly the values of `observe` and `responseType`).
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient) {}

  /**
   * Load Active Member Sites from DB
   * @param { MemberSiteFilter } memberSiteFilter
   * @returns { Observable<Array<MemberSiteInfo>> }
   */
  public loadMemberSites(memberSiteFilter: MemberSiteFilter): Observable<Array<MemberSiteInfo>> {
    return this.http.get(`${this.BASE_URL}/search`, {
      params: new HttpModel().setParams({ memberSiteFilter })
    }).pipe(map((response: Array<MemberSiteInfo>) => response));
  }

  /**
   * Method for getting participation url
   * @param {number} member_id
   * @return Observable<string>
   */
  public getParticipationUrl(member_id: number): Observable<string> {
    return this.http.get(`${this.BASE_URL}/tableau`, {
      params: new HttpModel().setParams({ member_id })
    }).pipe(map((response: { standard_url: string }) => response.standard_url));
  }

  /**
   * Method for getting member site info
   * @returns {Observable<MemberSiteInfo>}
   */
  public getMemberSiteInfo(): Observable<MemberSiteInfo> {
    return this.http.get(`${this.BASE_URL}/info`).pipe(
      shareReplay(1),
      map((memberSiteInfo: MemberSiteInfo) => new MemberSiteInfo().deserialize(memberSiteInfo)),
      catchError((error: AppHttpError) => throwError(error))
    )
  }
}
