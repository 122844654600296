/**
 * Success False class
 * Extends Error class
 * When status code is 200, but "success" property has value of "false"
 */
export class SuccessFalse extends Error {

  /**
   * Http Response Status code
   * @type { number }
   */
  private status: number;

  /**
   * Dependency injection for Error message
   * @param { string } message
   */
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, SuccessFalse.prototype);
    this.status = 200;
  }
}
