import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

/**
 * Click event outside element detector directive
 */
@Directive({
  selector: '[outsideElementClickEventDetector]'
})
export class DetectOutsideElementClickDirective {
  /**
   * Click event outside element detector directive constructor
   * @param {ElementRef} elementRef
   */
  constructor(private elementRef: ElementRef) {}

  /**
   * Emit to component when is clicked outside the element
   * @type {EventEmitter<boolean>}
   */
  @Output() clickedOutside: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Listener when user clicks outside the element
   * @param targetElement
   */
  @HostListener('document:click', ['$event.target']) onMouseEnter(targetElement) {
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.clickedOutside.emit(false);
    }
  }
}
