import { createAction, props } from '@ngrx/store';

/**
 * Set Loading Spinner state is displayed or not action
 */
export const toggle = createAction(
  '[Layout] toggle loading spinner',
  props<{ isLoading: boolean }>()
);

/**
 * Loading Spinner Actions Type
 */
export type LoadingSpinnerActionsUnion = ReturnType<typeof toggle>;
