import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Credentials } from '../utils/types/credentials.type';
import { User } from '../utils/types/user.type';
import { ErrorInfo } from '../utils/types/error-info.type';
import { throwError, of } from 'rxjs';

import { WindowService } from '@imentor-shell-app/core/services/window.service';
import {  AuthenticationApiActions,} from '../store/actions';
import { AppHttpError } from '@imentor-shell-app/core/errors/common/app-http-error';
import { FoundResultsError } from '@imentor-shell-app/core/errors/common/found-results-error';

/**
 * Authentication Service
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  /**
   * BASE URL for user's authentication
   * @TODO: Change base URL after rewriting legacy authentication API
   * @type string
   * @private
   */
  private readonly BASE_URL: string = '/iuser';

  /**
   * Dependency injection of HttpClient class
   *
   * `HttpClient` is available as an injectable class, with methods to perform HTTP requests.
   * Each request method has multiple signatures, and the return type varies according to which
   * signature is called (mainly the values of `observe` and `responseType`).
   * @param {HttpClient} http
   * @param windowService 
   */
  constructor(private http: HttpClient, private windowService: WindowService) {}

  /**
   * Method for logging in user
   * @param {Credentials} credentials
   * @returns {Observable<string>}
   */
  public loginUser(credentials: Credentials): Observable<string> {
    return this.http.post(`${this.BASE_URL}/v2/login/`, credentials, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    }).pipe(map((response: any) => response.url));
  }

  /**
   * Method for signing up user
   * @param {User} user
   * @returns {Observable<ErrorInfo>}
   */
  public signUpUser(user: User): Observable<ErrorInfo> {
    return this.http.post(`${this.BASE_URL}/ajax_createuser/`, {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      primary_phone_number: user.primary_phone,
      gender_id: user.gender.id,
      password: user.passwords.password,
      password_confirm: user.passwords.confirm_password,
      url: user.url,
      user_type: user.user_type
    }, {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
    }).pipe(map((response: ErrorInfo) => response));
  }

  /**
   * Method for deleting session cookies
   * @returns {Observable<any>}
   */
  public clearSessionCookies(): Observable<any> {
    return this.http.post(`${this.BASE_URL}/clear_session_cookies/`, {},
    {
      headers: new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' }),
      observe: 'response'
    }).pipe(map(response => response));
  }

}
