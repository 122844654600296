import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { AppHttpError } from '../common/app-http-error';
import { SuccessFalse } from '../common/success-false';
import { NotificationService } from '../../services/notification.service';
import { LoggerService } from '../../services/logger.service';
import { Unauthorized } from '../common/unauthorized';
import { Router } from '@angular/router';
import { Forbidden } from '../common/forbidden';
import { WindowService } from '../../services/window.service';

/**
 * Application Errors Handler class.
 * Extends Angular Error Handler class, which provides a hook for centralized exception handling.
 */
@Injectable()
export class ErrorsHandler implements ErrorHandler {

  /**
   * Dependency injection for Injector.
   * Since error handling is loaded first, we can't use dependency injection in the constructor for the services.
   * Instead, we have to inject them manually with Injector.
   * @param { Injector } injector
   */
  constructor(private injector: Injector) {}

  /**
   * Error Handler function
   * @param { any } error
   */
  handleError(error: any): void {
    let message: string;
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    const notificationService = this.injector.get(NotificationService);
    const loggerService = this.injector.get(LoggerService);
    const router = this.injector.get(Router);
    const windowService = this.injector.get(WindowService);

    if (!navigator.onLine) {
      message = 'No Internet connection.';
    } else if (chunkFailedMessage.test(error.message)) {
      windowService.locationReload();
      return;
    } else if (error instanceof Unauthorized || error instanceof Forbidden) {
      router.navigate(['/unauthorized']);
      return;
    } else if (error instanceof AppHttpError) {
      message = error.originalError.error.message || 'Something went wrong.';
    } else if (error instanceof SuccessFalse) {
      message = error.message;
    } else {
      loggerService.error(error);
      return;
    }
    notificationService.showError(message);
  }
}
