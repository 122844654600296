import { Compiler, Injectable, Injector, NgModuleFactory, NgModuleRef, Type } from '@angular/core';

/**
 * Lazy Module Loader service
 */
@Injectable({ providedIn: 'root' })
export class LazyModuleLoaderService {

  /**
   * Lazy Module Loader service constructor
   * @param { Compiler } compiler
   * @param { Injector } injector
   */
  constructor(private compiler: Compiler,
              private injector: Injector) {}

  /**
   * Load Lazy Module
   * @param { Promise<any> } module
   * @return { Promise<NgModuleRef<any>> }
   */
  public loadLazyModule(module: Promise<any>): Promise<NgModuleRef<any>> {
    return module
      .then((elementModule: Type<any>) => this.compiler.compileModuleAsync(elementModule))
      .then((moduleFactory: NgModuleFactory<any>) => moduleFactory.create(this.injector));
  }
}
