import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SuccessFalse } from '../common/success-false';
import { AppHttpError } from '../common/app-http-error';
import { ValidationError } from '../common/validation-error';
import { Unauthorized } from '../common/unauthorized';
import { Forbidden } from '../common/forbidden';
import { NotFound } from '../common/not-found';
import { UnsupportedMediaType } from '../common/unsupported-media-type';
import { FoundResultsError } from '../common/found-results-error';
import { Conflicts } from '../common/conflicts';
import { LoadingSpinnerActions } from '../../layout/store/actions';
import { Store } from '@ngrx/store';
import * as fromAppRoot from '../../store';

/**
 * Application Response Interceptor class.
 * Extends Angular Http Interceptor class
 * Which intercepts an outgoing `HttpRequest` and optionally transforms it, and an incoming `HttpResponse`.
 */
@Injectable({ providedIn: 'root'})
export class ResponseInterceptor implements HttpInterceptor {

  /**
   * Dependency injection for Store
   * @param {Store<State>} store
   */
  constructor (private store: Store<fromAppRoot.AppRootState>) {}

  /**
   * Http Interceptor function
   * @param { HttpRequest<any> } request
   * @param { HttpHandler } next
   * @returns { Observable<HttpEvent<any>> }
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.status === 200 && event.body.hasOwnProperty('success') && !event.body.success) {
              throw new SuccessFalse(event.body.message || event.body.error || 'Something went wrong.');
            }
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.store.dispatch(LoadingSpinnerActions.toggle({ isLoading: false }));

          switch (error.status) {
            case 200: {
              return throwError(error);
            }

            case 400: {
              return throwError(new ValidationError(error));
            }

            case 401: {
              return throwError(new Unauthorized(error));
            }

            case 403: {
              return throwError(new Forbidden(error));
            }

            case 404: {
              return throwError(new NotFound(error));
            }

            case 409: {
              return throwError(new Conflicts(error));
            }

            case 415: {
              return throwError(new UnsupportedMediaType(error));
            }

            case 422: {
              return throwError(new FoundResultsError(error));
            }

            default: {
              return throwError(new AppHttpError(error));
            }
          }
        })
      );
  }
}
