import { ViewContainerRef } from '@angular/core';

import { createAction, props, union } from '@ngrx/store';

import { PasswordStrengthData } from '@imentor-user/user-password/user-password-strength/utils/types/password-strength-data.type';
import { ResetPasswordData } from '@imentor-user/user-password/user-password-builder/utils/types/reset-password-data.type';
import { PopUpSettings } from '@imentor-shared/modules/pop-up/utils/types/pop-up-settings.type';

/**
 * Request Reset password action
 */
export const reset = createAction(
  '[User Password] request reset password for user',
  props<{ email: string }>()
);

/**
 * Check Password Strength action
 */
export const check = createAction(
  '[User Password] check user\'s password strength',
  props<{ passwordStrengthData: Partial<PasswordStrengthData> }>()
);

/**
 * Update/change user's password action
 */
export const update = createAction(
  '[User Password] change password',
  props<{ resetPasswordData: Partial<ResetPasswordData>, popUpContainer: ViewContainerRef, popUpSettings: PopUpSettings }>()
);

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
const all = union({
  reset,
  check,
  update,
});

/**
 * User password Actions Types
 */
export type UserPasswordActionsUnion = typeof all;
