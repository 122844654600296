import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from '../../../environments/environment';
import { Environments } from '../utils/enums/environments.enum';

/**
 * Logger service
 */
@Injectable({ providedIn: 'root' })
export class LoggerService {

  /**
   * Log logger. If environment isn't production, then throw log in browser console
   * @param value
   * @param rest
   */
  log(value: any, ...rest: any[]) {
    if (environment.name !== Environments.PRODUCTION) {
      let sanitizedRest: any[] = []
      let sanitizedValue: string = ''
      if(typeof value == 'string'){
        sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
      }
      if(rest){
        for(const item of rest){
          if(typeof item === 'string'){
            const sanitizedString = item.replace(/[^a-zA-Z0-9\s]/g, '');
            sanitizedRest.push(sanitizedString)
          } else {
            sanitizedRest.push(item)
          }
        }
      }
      if(sanitizedValue){
        console.log(sanitizedValue, ...sanitizedRest)
      } else {
        console.log(value, ...sanitizedRest)
      }
    }
  }

  /**
   * Error logger. If environment is production, staging, or test/dev then send error on Sentry.
   * If environment is staging, test/dev, or local then throw error in browser console
   * @param {Error | string} error
   */
  error(error: Error | string) {
    if (environment.production) {
      Sentry.captureException(error);
    }

    if (environment.name !== Environments.PRODUCTION) {
      console.error(error);
    }
  }

  /**
   * Warning logger. If environment isn't production, then throw warn in browser console
   * @param value
   * @param rest
   */
  warn(value: any, ...rest: any[]) {
    if (environment.name !== Environments.PRODUCTION) {
      console.warn(value, ...rest);
    }
  }
}
