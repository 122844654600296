import { Component } from '@angular/core';

import { PopUpSettings } from './utils/types/pop-up-settings.type';

/**
 * iMentor Pop-up component
 */
@Component({
  selector: 'imentor-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent {
  /**
  * Pop-up settings
  * @type { PopUpSettings }
  */
  public popUpSettings: PopUpSettings;

  /**
   * Close the alert pop-up when clicked outside
   */
  public closePopUp() {
    if (this.popUpSettings.alertTypeClosingAction) {
      this.popUpSettings.alertTypeClosingAction();
    }
  }
}
