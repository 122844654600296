/**
 * Member names enum for hiding
 * Intake Application Buttons
 * for specific member site names
 * https://imentor.atlassian.net/browse/IP-3467
 */
export enum MemberNames {
  CHICAGO = 'iMentor Chicago',
  STUDENT_SPONSOR_PARTNERS = 'Student Sponsor Partners',
  NYC = 'iMentor NYC',
  BALTIMORE = 'iMentor Baltimore'
}
