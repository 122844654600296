import * as Sentry from '@sentry/angular';
import { environment } from '../../../environments/environment';
import { Integrations } from '@sentry/tracing';

/**
 * Function for initializing Sentry tool.
 */
export const initializeSentry = () => {
  Sentry.init({
    dsn: environment.sentry.dsn,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Integrations.BrowserTracing({
        tracingOrigins: [document.location.origin + document.location.pathname + '#/'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment.sentry.sampleRate,
  });
}

