import { PasswordStrengthResult } from '@imentor-user/user-password/user-password-strength/utils/types/password-strength-result.type';
import {
  AuthenticationActionsTypes,
  AuthenticationActions,
  AuthenticationApiActions,
  UserPasswordActions,
  UserPasswordApiActions,
  MentorPersonaActions,
} from '../actions';

/**
 * Authentication state interface
 */
export interface State {

  /**
   * State for error login message
   * @type { string }
   */
  error_login_message: string;

  /**
   * State for error request reset password message
   * @type { string }
   */
  error_request_reset_password_message: string;

  /**
   * State for error sign up message
   * @type { string }
   */
  error_sign_up_message: string;

  /**
   * State for error reset password message
   * @type { string }
   */
  error_reset_password_message: string;

  /**
   * State for error expired link reset password message
   * @type { string }
   */
  error_expired_link_reset_password_message: string;

  /**
   * State for submit button state
   * @type { boolean }
   */
  is_submitting: boolean;

  /**
   * Password Strength Result
   * @type { PasswordStrengthResult }
   */
  password_strength_result: PasswordStrengthResult;
}

/**
 * Initial authentication state
 * @type {State}
 */
export const initialState: State = {
  error_login_message: null,
  error_request_reset_password_message: null,
  error_sign_up_message: null,
  error_reset_password_message: null,
  error_expired_link_reset_password_message: null,
  is_submitting: false,
  password_strength_result: null,
};

/**
 * Authentication reducer
 * @param {State} state
 * @param {AuthenticationActionsTypes} action
 * @returns {State}
 */
export function reducer(state: State = initialState, action: AuthenticationActionsTypes): State {
  switch (action.type) {

    case AuthenticationActions.reset.type: {
      return {
        ...state,
        error_login_message: initialState.error_login_message,
        error_sign_up_message: initialState.error_sign_up_message,
        error_request_reset_password_message: initialState.error_request_reset_password_message,
        error_reset_password_message: initialState.error_reset_password_message,
        error_expired_link_reset_password_message: initialState.error_expired_link_reset_password_message
      }
    }

    case AuthenticationActions.signUp.type:
    case AuthenticationActions.login.type:
    case UserPasswordActions.update.type:
    case MentorPersonaActions.create.type: {
      return {
        ...state,
        error_reset_password_message: initialState.error_reset_password_message,
        error_expired_link_reset_password_message: initialState.error_expired_link_reset_password_message,
        is_submitting: true
      };
    }

    case AuthenticationApiActions.authSuccess.type: {
      return {
        ...state,
        error_login_message: initialState.error_login_message,
        error_request_reset_password_message: initialState.error_request_reset_password_message,
        error_sign_up_message: initialState.error_sign_up_message,
        error_reset_password_message: initialState.error_reset_password_message,
        error_expired_link_reset_password_message: initialState.error_expired_link_reset_password_message,
        is_submitting: initialState.is_submitting
      };
    }

    case AuthenticationApiActions.loginFailure.type: {
      return {
        ...state,
        error_login_message: action.message,
        is_submitting: initialState.is_submitting
      };
    }

    case UserPasswordApiActions.resetFailure.type: {
      return {
        ...state,
        error_request_reset_password_message: action.message,
      };
    }

    case AuthenticationApiActions.signUpFailure.type: {
      return {
        ...state,
        error_sign_up_message: action.message,
        is_submitting: initialState.is_submitting
      };
    }

    case UserPasswordApiActions.updateFailure.type: {
      return {
        ...state,
        ...action.errorType === 'EXPIRED_LINK' && {
          error_expired_link_reset_password_message: action.message
        },
        ...action.errorType === 'UPDATE_FAILURE' && {
          error_reset_password_message: action.message,
        },
        is_submitting: initialState.is_submitting
      };
    }

    case UserPasswordApiActions.checkSuccess.type: {
      return {
        ...state,
        password_strength_result: action.passwordStrengthResult
      };
    }

    default: {
      return state;
    }
  }
}

/**
 * Get login error message from auth reducer
 * @param {State} state
 * @returns {string}
 */
export const getLoginErrorMessage = (state: State): string => state.error_login_message;

/**
 * Get request reset password error message from auth reducer
 * @param {State} state
 * @returns {string}
 */
export const getRequestResetPasswordErrorMessage = (state: State): string => state.error_request_reset_password_message;

/**
 * Sign up error message from auth reducer
 * @param {State} state
 * @returns {string}
 */
export const getSignUpErrorMessage = (state: State): string => state.error_sign_up_message;

/**
 * Get reset password error message from auth reducer
 * @param {State} state
 * @returns {string}
 */
export const getResetPasswordErrorMessage = (state: State): string => state.error_reset_password_message;

/**
 * Get expired link reset password error message from auth reducer
 * @param {State} state
 * @returns {string}
 */
export const getExpiredLinkResetPasswordErrorMessage = (state: State): string => state.error_expired_link_reset_password_message;

/**
 * Submit button state from auth reducer
 * @param {State} state
 * @returns {boolean}
 */
export const getSubmitButtonState = (state: State): boolean => state.is_submitting;

/**
 * Get password strength result state from auth reducer
 * @param {State} state
 * @returns {PasswordStrengthResult}
 */
export const getPasswordStrengthResult = (state: State): PasswordStrengthResult => state.password_strength_result;
