<div *ngIf="popUpSettings" class="pop-up-window" [ngClass]="{'montserrat-look': popUpSettings.isMontserratLook}">
  <div class="pop-up-dialog"
       [ngStyle]="{'width': popUpSettings.width}">
    <div class="pop-up-content"
         outsideElementClickEventDetector
         (clickedOutside)="closePopUp()">
      <div class="pop-up-header" [innerHTML]="popUpSettings.title"></div>
      <div class="pop-up-body">
        <div class="pop-up-body-text" [innerHTML]="popUpSettings.text"></div>
        <ng-content select="[pop-up-body]"></ng-content>
        <div class="action-buttons im-button-container">
        <ng-container *ngFor="let button of popUpSettings.buttons">
          <span class="imentor-btn-container"
                tabindex="1"
                (keyup.enter)="button.action()"
                (keyup.Space)="button.action()">
            <button class="btn"
                    [ngClass]="popUpSettings.isMontserratLook ?
                      ('im-button ' + (button.type === 'primary' ?
                         (button.color === 'green' ? 'primary' : 'secondary') :
                         'additional')) :
                      'imentor-btn-' + button.type + ' ' + button.color"
                    [disabled]="button.isDisabled"
                    (click)="button.action()"
                    [innerHTML]="button.text"
                    tabindex="-1">
            </button>
          </span>
        </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="pop-up-backdrop"></div>
