import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';

/**
 * iMentor platform authentication routes
 * @type { Route[] }
 */
const routes: Route[] = [
  {
    path: '',
    loadChildren: () => import('./pages/sign-in-page/sign-in-page.module')
      .then(m => m.SignInPageModule),
    data: {
      pageTitle: 'Welcome'
    },
  },
  {
    path: 'signup/:userType',
    loadChildren: () => import('./pages/sign-up-page/sign-up-page.module')
      .then(m => m.SignUpPageModule),
    data: {
      pageTitle: 'Sign Up',
    },
  },
  {
    path: 'password-forgot',
    loadChildren: () => import('./pages/forgot-password-page/forgot-password-page.module')
      .then(m => m.ForgotPasswordPageModule),
    data: {
      pageTitle: 'Forgot Password',
    },
  },
  {
    path: 'password-sent',
    loadChildren: () => import('./pages/password-sent-page/password-sent-page.module')
      .then(m => m.PasswordSentPageModule),
    data: {
      pageTitle: 'Sent Link',
    },
  },
  {
    path: 'application-info/:userType',
    loadChildren: () => import('./pages/application-info-page/application-info-page.module')
      .then(m => m.ApplicationInfoPageModule),
    data: {
      pageTitle: 'Info',
    },
  },
  {
    path: 'masquerade',
    loadChildren: () => import('./masquerade/masquerade.module')
      .then(m => m.MasqueradeModule),
    data: {
      pageTitle: 'Masquerade',
    }
  },
  {
    path: 'reset-password/:hash/:userTypeId/:isExpired/:isTemporary',
    loadChildren: () => import('./pages/reset-password-page/reset-password-page.module')
      .then(m => m.ResetPasswordPageModule),
    data: {
      pageTitle: 'Reset Password'
    }
  },
  {
    path: 'not-found',
    loadChildren: () => import('@imentor-shell-app/core/errors/pages/not-found-page/not-found-page.module')
      .then(m => m.NotFoundPageModule),
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];

/**
 * Authentication routing module
 */
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [ RouterModule ]
})
export class AuthRoutingModule { }
