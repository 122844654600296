import { Deserializable } from '../../../shared/utils/types/deserializable.type';
import { MemberTypes } from '../enums/member-types.enum';
import { BaseEntity } from '../../../shared/utils/types/base-entity.type';
import { MemberCustomizationItems } from '../types/member-customization-items.type';

/**
 * Member site info model
 */
export class MemberSiteInfo implements Deserializable<MemberSiteInfo> {
  /**
   * Unique identifier of Member Site
   * @type number
   */
  id: number;

  /**
   * Name property
   * @type string
   */
  name: string;

  /**
   * Abbreviation property
   * @type string
   */
  abbreviation: string;

  /**
   * Url name property
   * @type string
   */
  url_name: string;

  /**
   * Member site logo
   * @type string
   */
  logo: string;

  /**
   * Member site type template
   * @type {BaseEntity<string>}
   */
  type_template: BaseEntity<string>;

  /**
   * Orientation link
   * @type string
   */
  orientation_link: string;

  /**
   * Partners link
   * @type string
   */
  partners_link: string;

  /**
   * State whether Mentee Application button should be displayed or not
   * @type boolean
   */
  mentee_registration_enabled: boolean;

  /**
   * State whether Mentor Application button should be displayed or not
   * @type boolean
   */
  mentor_registration_enabled: boolean;

  /**
   * Time zone
   * @type {BaseEntity<string>}
   */
  time_zone: BaseEntity<string>;

  /**
   * Member customization items
   * @type { MemberCustomizationItems }
   */
  member_customization_items: MemberCustomizationItems;

  /**
   * Method for deserializing input and returning Member info model
   * @param input
   * @returns {MemberSiteInfo}
   */
  public deserialize(input: any): MemberSiteInfo {
    Object.assign(this, input);
    return this;
  }

  /**
   * Method for checking whether is member type is one from member type list in method argument
   * @param {Array<MemberTypes>} memberTypeList
   * @returns {boolean}
   */
  public currentMemberTypeIsOneOf(memberTypeList: Array<MemberTypes>): boolean {
    return memberTypeList.some((memberType:MemberTypes) => this.isCurrentMemberType(memberType));
  }

  /**
   * Method for getting member profile link
   * @returns {string}
   */
  public getMemberProfileLink(): string {
    return `/member/profile/${this.id}/`;
  }

    /**
   * Method for getting member message link
   * @returns {string}
   */
  public getMemberMessageLink(): string {
    return `/member/messaging/${this.id}/`;
  }

  /**
   * Method for getting member site logo
   * @returns string
   */
  public getMemberSiteLogo(): string {
    return this.logo;
  }

  /**
   * Method for checking whether is current member type or not
   * @param {MemberTypes} memberType
   * @returns {boolean}
   */
  public isCurrentMemberType(memberType: MemberTypes): boolean {
    return this.type_template.name === memberType;
  }

  /**
   * Method for getting value of specific member customization item
   * @param { string } customizationItem
   * @return boolean
   */
  public getMemberCustomizationItem(customizationItem: string): boolean {
    return this.member_customization_items[customizationItem];
  }
}
