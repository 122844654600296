import { Injectable, Injector, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';

import { of, throwError } from 'rxjs';
import { catchError, debounceTime, exhaustMap, map, skip, switchMap, takeUntil } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { AppHttpError } from '@imentor-shell-app/core/errors/common/app-http-error';
import { PasswordStrengthResult } from '@imentor-user/user-password/user-password-strength/utils/types/password-strength-result.type';
import { SuccessFalse } from '@imentor-shell-app/core/errors/common/success-false';
import { ValidationError } from '@imentor-shell-app/core/errors/common/validation-error';
import { WindowService } from '@imentor-shell-app/core/services/window.service';
import { PopUpSettings } from '@imentor-shared/modules/pop-up/utils/types/pop-up-settings.type';
import { PopUpService } from '@imentor-shared/modules/pop-up/services/pop-up.service';
import { PopUpComponent } from '@imentor-shared/modules/pop-up/pop-up.component';
import { FoundResultsError } from '@imentor-shell-app/core/errors/common/found-results-error';
import { Conflicts } from '@imentor-shell-app/core/errors/common/conflicts';
import { UserPersonaService } from '@imentor-user/user-persona/services/user-persona.service';

import { AuthenticationService } from '../../services/authentication.service';
import {
  AuthenticationActions,
  AuthenticationApiActions,
  UserPasswordActions,
  UserPasswordApiActions,
  ApplicationActions,
  MentorPersonaActions
} from '../actions';
import { User } from '../../utils/types/user.type';
import { UserPasswordService } from '../../services/user-password.service';
import { ApplicationsService } from '../../services/applications.service';

/**
 * Authentication effects
 */
@Injectable()
export class AuthEffects {

  /**
   * Authentication effects constructor
   * @param { Actions } actions$
   * @param { Router } router
   * @param { UserPasswordService } userPasswordService
   * @param { AuthenticationService } authenticationService
   * @param { ApplicationsService } applicationsService
   * @param { WindowService } windowService
   * @param { Injector } injector
   * @param { UserPersonaService } userPersonaService
   */
  constructor(
    private actions$: Actions,
    private router: Router,
    private userPasswordService: UserPasswordService,
    private authenticationService: AuthenticationService,
    private applicationsService: ApplicationsService,
    private windowService: WindowService,
    private injector: Injector,
    private userPersonaService: UserPersonaService
  ) {}

  /**
   * Open pop up
   * @param { ViewContainerRef } popUpContainer
   * @param { PopUpSettings } popUpSettings
   * @param { string } text
   */
  private openPopUp(popUpContainer: ViewContainerRef, popUpSettings: PopUpSettings, text: string) {
    this.injector.get(PopUpService).openPopUp(popUpContainer).then((component: PopUpComponent) => {
      component.popUpSettings = { ...popUpSettings, text };
    })
  };

  /**
   * @ignore
   */
  createNewMentorPersona$ = createEffect(() => this.actions$.pipe(
    ofType(MentorPersonaActions.create.type),
    switchMap(({ credentials }) => this.userPersonaService.createNewMentorPersona(credentials).pipe(
      map((url: string) => {
        this.windowService.locationReplace(url);
        return AuthenticationApiActions.authSuccess();
      }),
      catchError((error: AppHttpError) => of(AuthenticationApiActions.loginFailure({ message: error.originalError.error.message })))
    ))
  ));

  /**
   * @ignore
   */
  loginUser$ = createEffect(() => this.actions$.pipe(
    ofType(AuthenticationActions.login.type),
    exhaustMap(({ credentials, popUpContainer, passwordExpiredPopUpSettings, mentorClosedPopUpSettings }) => this.authenticationService.loginUser(credentials).pipe(
      map((url: string) => {
        this.windowService.locationReplace(url);
        return AuthenticationApiActions.authSuccess();
      }),
      catchError((error: AppHttpError) => {
        if (error instanceof FoundResultsError) {
          this.openPopUp(popUpContainer, passwordExpiredPopUpSettings, error.originalError.error.message);
          return of(AuthenticationApiActions.loginFailure({ message: null }));
        } else if (error instanceof Conflicts) {
          this.openPopUp(popUpContainer, mentorClosedPopUpSettings, error.originalError.error.message);
          return of(AuthenticationApiActions.loginFailure({ message: null }));
        } else {
          return of(AuthenticationApiActions.loginFailure({ message: error?.originalError?.error?.message }));
        }
      })
    ))
  ));

  /**
   * @ignore
   */
  signUpUser$ = createEffect(() => this.actions$.pipe(
    ofType(AuthenticationActions.signUp.type),
    exhaustMap(({ user }) => {
      const selectedUser: User = user;
      return this.authenticationService.signUpUser(user).pipe(
        map(() => {
          this.router.navigate(['/application-info/', selectedUser.user_type]);
          return AuthenticationApiActions.authSuccess();
        }),
        catchError((error: any) => {
          if (error instanceof SuccessFalse) {
            return of(AuthenticationApiActions.signUpFailure({ message: error.message }));
          } else {
            return throwError(error);
          }
        })
      );
    })
  ));

  /**
   * @ignore
   */
  requestResetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(UserPasswordActions.reset.type),
    exhaustMap(({ email }) => this.userPasswordService.requestResetPassword(email).pipe(
      map(() => {
        this.router.navigate(['/password-sent']);
        return AuthenticationApiActions.authSuccess();
      }),
      catchError((error: AppHttpError) =>
        of(UserPasswordApiActions.resetFailure({
          message: error?.originalError?.error?.message
        }))
      )
    ))
  ));

  /**
   * @ignore
   */
  checkPasswordStrength$ = createEffect(() => this.actions$.pipe(
    ofType(UserPasswordActions.check.type),
    debounceTime(600),
    switchMap(({ passwordStrengthData }) => {

      const nextSearch$ = this.actions$.pipe(
        ofType(UserPasswordActions.check.type),
        skip(1)
      );

      return this.userPasswordService.checkPasswordStrength(passwordStrengthData).pipe(
        takeUntil(nextSearch$),
        map((passwordStrengthResult: PasswordStrengthResult) =>
          UserPasswordApiActions.checkSuccess({ passwordStrengthResult })
        ),
        catchError((error: AppHttpError) => throwError(error))
      );
    })
  ));

  /**
   * @ignore
   */
  resetPassword$ = createEffect(() => this.actions$.pipe(
    ofType(UserPasswordActions.update.type),
    exhaustMap(({ resetPasswordData, popUpContainer, popUpSettings }) =>
      this.userPasswordService.resetPassword(resetPasswordData).pipe(
        map(() => {
          this.router.navigate(['']);
          return AuthenticationApiActions.authSuccess();
        }),
        catchError((error: AppHttpError) => {
          if (error instanceof FoundResultsError) {
            this.openPopUp(popUpContainer, popUpSettings, error.originalError.error.message);
            return of(UserPasswordApiActions.updateFailure({
              message: '',
              errorType: 'UPDATE_FAILURE'
            }));
          } else {
            return of(UserPasswordApiActions.updateFailure({
              message: error?.originalError?.error?.message,
              errorType: error instanceof ValidationError ? 'EXPIRED_LINK' : 'UPDATE_FAILURE'
            }))
          }
        })
      )
    )
  ));

  /**
   * @ignore
   */
  startApplication$ = createEffect(() => this.actions$.pipe(
    ofType(ApplicationActions.start.type),
    exhaustMap(() => this.applicationsService.startApplication().pipe(
      map((url: string) => this.windowService.locationReplace(url)),
      catchError((error: AppHttpError) => throwError(error))
    )),
  ), { dispatch: false });

}
