import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PasswordStrengthData } from '@imentor-user/user-password/user-password-strength/utils/types/password-strength-data.type';
import { PasswordStrengthResult } from '@imentor-user/user-password/user-password-strength/utils/types/password-strength-result.type';
import { HttpModel } from '@imentor-shell-app/shared/models/http.model';
import { ResetPasswordData } from '@imentor-user/user-password/user-password-builder/utils/types/reset-password-data.type';

/**
 * User password service
 */
@Injectable()
export class UserPasswordService {

  /**
   * Base URL for User Passwords blueprint
   * @type { string }
   */
  private readonly BASE_URL: string = '/v2/passwords';

  /**
   * Dependency injection of HttpClient class
   *
   * `HttpClient` is available as an injectable class, with methods to perform HTTP requests.
   * Each request method has multiple signatures, and the return type varies according to which
   * signature is called (mainly the values of `observe` and `responseType`).
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient) {}

  /**
   * Request Reset Password for active user
   * @param {string} email
   * @returns {Observable<string>}
   */
  public requestResetPassword(email: string): Observable<string> {
    return this.http.post(`${this.BASE_URL}/request-reset-link`, { email: email })
      .pipe(map((response: string) => response));
  }

  /**
   * Check password strength
   * @param { Partial<PasswordStrengthData> } data
   * @returns { Observable<PasswordStrengthResult> }
   */
  public checkPasswordStrength(data: Partial<PasswordStrengthData>): Observable<PasswordStrengthResult> {
    return data.password.length > 0 ?
      this.http.get(`${this.BASE_URL}/strength`, { params: new HttpModel().setParams(data) })
        .pipe(map((response: PasswordStrengthResult) => response)) :
      of(null);
  }

  /**
   * Reset Password for active user
   * @param { Partial<ResetPasswordData> } data
   * @returns {Observable<string>}
   */
  public resetPassword(data: Partial<ResetPasswordData>): Observable<string> {
    return this.http.post(`${this.BASE_URL}/reset`, data)
      .pipe(map((response: string) => response));
  }
}
